<template>
  <v-container fluid pa-1>
    <v-card tile flat class="blue-grey lighten-5">
      <v-card-title>Add/Edit Tariff</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-container fluid pa-0>
          <v-row no-gutters class="mb-2">
            <v-col cols="2" class="text-right align-self-center black--text">Name</v-col>
            <v-col cols="4" class="ml-3">
              <v-text-field
                hide-details
                v-model="tariff.name"
                label="Name"
                outlined
                dense
                solo
                flat
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters class="mb-2">
            <v-col cols="2" class="text-right align-self-center black--text">Description</v-col>
            <v-col cols="4" class="ml-3">
              <v-text-field
                hide-details
                v-model="tariff.description"
                label="Description"
                outlined
                dense
                solo
                flat
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters class="mb-2">
            <v-col cols="2" class="text-right align-self-center black--text">Default Cost (per kWh)</v-col>
            <v-col cols="4" class="ml-3">
              <v-text-field
                hide-details
                v-model="tariff.default_cost"
                label="Default Cost"
                type="number"
                outlined
                dense
                solo
                flat
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters class="mb-2">
            <v-col cols="2" class="text-right align-self-center black--text">Min Monthly Charge</v-col>
            <v-col cols="4" class="ml-3">
              <v-text-field
                hide-details
                v-model="tariff.min_monthly_charge"
                label="Min Monthly Charge"
                type="number"
                outlined
                dense
                solo
                flat
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters class="mb-2">
            <!-- <v-col cols="2" class="text-right align-self-center black--text">Tariff Rules</v-col> -->
            <v-col cols="10" class="ml-3">
              <v-card tile flat>
                <v-card-title>
                  <span>Tariff Rules</span>
                  <v-spacer></v-spacer>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        class="ml-2"
                        icon
                        color="green"
                        v-on="on"
                        dark
                        @click="addTariffRuleDialog = true"
                      >
                        <v-icon dark>add</v-icon>
                      </v-btn>
                    </template>
                    <span>Add tariff rule</span>
                  </v-tooltip>
                </v-card-title>
                <v-card-text>
                  <v-simple-table dense>
                    <template v-slot:default>
                      <thead>
                        <tr class="text-center">
                          <th class="text-center">Min Range</th>
                          <th class="text-center">Max Range</th>
                          <th class="text-center">Cost Per kWh</th>
                          <th class="text-center">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="rule in tariff.rules" :key="rule.id" class="text-center">
                          <td>{{ rule.min_range }}</td>
                          <td>{{ rule.max_range == null ? "-" : rule.max_range }}</td>
                          <td>{{ rule.cost_kwh }}</td>
                          <td>
                            <v-tooltip top>
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  class="ml-2"
                                  icon
                                  color="green"
                                  v-on="on"
                                  dark
                                  @click="onDeleteTariffRule(rule)"
                                >
                                  <v-icon dark>delete</v-icon>
                                </v-btn>
                              </template>
                              <span>Delete tariff rule</span>
                            </v-tooltip>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn depressed color="green" class="white--text" @click="saveTariff">Save</v-btn>
        <v-btn depressed color="red" class="white--text" @click="cancel">Cancel</v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog v-model="addTariffRuleDialog" persistent max-width="800">
      <v-card>
        <v-card-title class="headline">Add Tariff Rule</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row no-gutters>
            <v-col
              cols="4"
              sm="6"
              class="align-self-center text-center subtitle-1"
            >Choose tariff rule type</v-col>
            <v-col cols="7" sm="6" class="py-2">
              <v-btn-toggle v-model="tariffRuleType" tile color="deep-purple accent-3" group>
                <v-btn value="range">Range</v-btn>
                <v-btn value="minValue">Only has Min Value</v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" class="px-2" v-if="tariffRuleType == 'range'">
              <v-row no-gutters>
                <v-col cols="11">
                  <v-range-slider
                    v-model="range"
                    :max="maxRange"
                    :min="minRange"
                    hide-details
                    class="align-center"
                  >
                    <template v-slot:prepend>
                      <v-text-field
                        :value="range[0]"
                        class="mt-0 pt-0"
                        single-line
                        hint="Min Range"
                        persistent-hint
                        type="number"
                        style="width: 80px"
                        @change="$set(range, 0, $event)"
                      ></v-text-field>
                    </template>
                    <template v-slot:append>
                      <v-text-field
                        :value="range[1]"
                        class="mt-0 pt-0"
                        single-line
                        type="number"
                        hint="Max Range"
                        persistent-hint
                        style="width: 80px"
                        @change="$set(range, 1, $event)"
                      ></v-text-field>
                    </template>
                  </v-range-slider>
                </v-col>
                <v-col cols="1">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn class="ml-2" icon color="grey" v-on="on">
                        <v-icon>info</v-icon>
                      </v-btn>
                    </template>
                    <span>{{range[0]}} &lt; consumption(kWh) &lt; {{range[1]}}</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-col>

            <v-col class="pr-4" v-else>
              <v-row no-gutters>
                <v-col cols="11">
                  <v-slider
                    v-model="minTariffRuleValue"
                    class="align-center"
                    :max="maxRange"
                    :min="minRange"
                    hide-details
                  >
                    <template v-slot:prepend>
                      <v-text-field
                        v-model="minTariffRuleValue"
                        class="mt-0 pt-0"
                        hint="Min Range"
                        persistent-hint
                        single-line
                        type="number"
                        style="width: 60px"
                      ></v-text-field>
                    </template>
                  </v-slider>
                </v-col>
                <v-col cols="1">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn class="ml-2" icon color="grey" v-on="on">
                        <v-icon>info</v-icon>
                      </v-btn>
                    </template>
                    <span>consumption(kWh) &gt; {{minTariffRuleValue}}</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col cols="2" class="text-right align-self-center black--text">Cost per kWh</v-col>
            <v-col cols="4" class="ml-3">
              <v-text-field
                hide-details
                v-model="tariffRuleCost"
                label="Cost per kWh"
                type="number"
                outlined
                dense
                solo
                flat
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="onAddTariffRule">Add</v-btn>
          <v-btn color="green darken-1" text @click="addTariffRuleDialog = false">Cancel</v-btn>
        </v-card-actions>
      </v-card>
      <v-dialog v-model="alertDialog" max-width="400">
        <v-card class="pa-3">
          <v-icon color="red">warning</v-icon>
          {{alertDialogMsg}}
          </v-card>
      </v-dialog>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from "axios";
export default {
  name: "tariff",
  components: {},
  data() {
    return {
      alertDialog: false,
      alertDialogMsg: "",
      addTariffRuleDialog: false,
      minTariffRuleValue: 0,
      tariffRuleType: "range",
      tariffRuleCost: null,
      range: [0, 3000],
      maxRange: 3000,
      minRange: 0,
      tariff: {
        name: null,
        description: null,
        default_cost: null,
        currency: "RM",
        min_monthly_charge: null,
        rules: [],
        org_idx: localStorage.getItem("orgId")
      }
    };
  },
  computed: {},
  watch: {},
  beforeCreate() {},
  beforeRouteEnter(to, from, next) {
    next(vm => {
      var self = vm;
      if (self.$router.currentRoute.name == "edittariff") {
        self.tariff = Object.assign({}, self.$route.params);
      } else {
        self.tariff = {
          name: null,
          description: null,
          currency: "RM",
          default_cost: null,
          min_monthly_charge: null,
          rules: [],
          org_idx: localStorage.getItem("orgId")
        };
      }
    });
  },
  mounted() {
    var self = this;
  },
  methods: {
    saveTariff() {
      var self = this;
      this.$store
        .dispatch({
          type: "MDMStore/upsertOrgTariff",
          data: this.tariff
        })
        .then(data => {
          this.$store.commit("MDMStore/SET_SNACKBAR_DETAILS", {
            text: "Tariff updated successfully",
            status: "success"
          });
          this.$store.dispatch({
            type: "MDMStore/getOrgTariffs"
          });
          this.$router.go(-1);
        });
    },
    cancel() {
      this.$router.go(-1);
    },
    onDeleteTariffRule(rule) {
      let tariffToDelete = this.tariff.rules.findIndex(x => x.id == rule.id);
      this.tariff.rules.splice(tariffToDelete, 1);
    },
    onAddTariffRule() {
      this.checkRuleValidity();
    },
    checkRuleValidity() {
      if( this.tariff.rules.length != 0){
        var maxMaxRangeValue = Math.max.apply(
          Math,
          this.tariff.rules.map(function(rule) {
            return rule.max_range;
          })
        );
      }else{
        var maxMaxRangeValue = 0;
      }
      var hasOnlyMinValue = this.tariff.rules.findIndex(rule => rule.max_range == null);
        if (this.tariffRuleType == "range" && hasOnlyMinValue == -1) {
          if (this.range[0] != maxMaxRangeValue) {
            this.alertDialogMsg = "Minimum value should be " + maxMaxRangeValue;
            this.alertDialog = true;
          } else {
            this.tariff.rules.push({
              min_range: this.range[0],
              max_range: this.range[1],
              cost_kwh: Number(this.tariffRuleCost)
            });
            this.addTariffRuleDialog = false;
          }
        } else if (this.tariffRuleType == "minValue"  && hasOnlyMinValue == -1) {
          if (this.minTariffRuleValue != maxMaxRangeValue) {
            this.alertDialogMsg = "Minimum value should be " + maxMaxRangeValue;
            this.alertDialog = true;
          } else {
            this.tariff.rules.push({
              min_range: this.minTariffRuleValue,
              max_range: null,
              cost_kwh: Number(this.tariffRuleCost)
            });
            this.addTariffRuleDialog = false;
          }
        }else if(hasOnlyMinValue != -1){
           this.alertDialogMsg = "There exists a tariff rule with only Min value. Please delete the rule with min value as "+ this.tariff.rules[hasOnlyMinValue].min_range + " to add a new rule." ;
            this.alertDialog = true;
        }
    }
  }
};
</script>
